import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Offcanvas, Badge, Form } from 'react-bootstrap';

const lightLogo = 'https://pub-c192afd771d147c4a5015cd3c52b99cb.r2.dev/lantern_logo.webp';
const darkLogo = 'https://pub-c192afd771d147c4a5015cd3c52b99cb.r2.dev/lantern_logo_dark_mode.webp';

const Navigation = () => {
  const [show, setShow] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [animateBadge, setAnimateBadge] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const theme = darkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-bs-theme', theme);
    document.body.className = darkMode ? 'dark-mode' : 'light-mode';
  }, [darkMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateBadge(false);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleDarkMode = () => setDarkMode(!darkMode);
  const logoSrc = darkMode ? darkLogo : lightLogo;

  return (
    <Navbar 
      expand={false} 
      bg={darkMode ? "dark" : "light"} 
      variant={darkMode ? "dark" : "light"}
      className="py-2"
      fixed="top"
    >
      <Container fluid className="px-2">
        <Navbar.Brand as={Link} to="/" onClick={handleClose} className="d-flex align-items-center">
          <img 
            src={logoSrc} 
            alt="Lantern Logo" 
            height="24"
            className="d-inline-block"
          />
        </Navbar.Brand>

        <div className="d-flex align-items-center gap-1">
          <Link 
            to="/updates" 
            style={{ textDecoration: 'none' }} 
            onClick={handleClose}
          >
            <Badge 
              bg="danger" 
              className={`${animateBadge ? "pulse-badge" : ""} px-2 py-1`}
              style={{ 
                fontSize: '12px',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                maxWidth: isMobile ? '80px' : 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Updates
            </Badge>
          </Link>

          <Navbar.Toggle 
            aria-controls="offcanvasNavbar"
            onClick={handleShow}
            className="border-0 p-0 ms-1"
            style={{ 
              width: '32px', 
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </div>

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          show={show}
          onHide={handleClose}
          className={darkMode ? "bg-dark text-light" : "bg-light"}
        >
          <Offcanvas.Header 
            closeButton 
            className="border-bottom"
          >
            <Offcanvas.Title id="offcanvasNavbarLabel">
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link as={Link} to="/" onClick={handleClose} className="py-2">
                Map
              </Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={handleClose} className="py-2">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/faq" onClick={handleClose} className="py-2">
                FAQ
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" onClick={handleClose} className="py-2">
                Contact
              </Nav.Link>
              <Nav.Link as={Link} to="/updates" onClick={handleClose} className="py-2">
                Updates - 10/19/2024
              </Nav.Link>
              
              <Form.Switch
                id="dark-mode-switch"
                label="Dark mode"
                checked={darkMode}
                onChange={toggleDarkMode}
                className="mt-3"
              />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navigation;
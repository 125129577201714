export const saveLocationToLocalStorage = (feature, lngLat) => {
  const savedLocations = JSON.parse(localStorage.getItem('savedLocations')) || [];
  const newLocation = {
    id: feature.id || new Date().getTime(),
    name: feature.properties.DESIG_ENG || feature.properties.DESIGNATION_ENG,
    lngLat: { lat: lngLat.lat ?? 0, lng: lngLat.lng ?? 0 },
    policyIdent: feature.properties.POLICY_IDENT || feature.properties.POL_IDENT,
  };
  savedLocations.push(newLocation);
  localStorage.setItem('savedLocations', JSON.stringify(savedLocations));
};

export const formatGoogleMapsLink = (lngLat) => `https://www.google.com/maps/search/?api=1&query=${lngLat.lat},${lngLat.lng}`;
export const formatPolicyIdentLink = (feature) => {
  const policyIdent = feature.properties.POLICY_IDENT || feature.properties.POL_IDENT;
  return policyIdent ? `https://www.lioapplications.lrc.gov.on.ca/services/CLUPA/xmlReader.aspx?xsl=web-primary.xsl&type=primary&POLICY_IDENT=${policyIdent}` : '#';
};
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Slider, Card, IconButton, Box, Typography, Collapse } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { initialLayers, initialLayerColors } from './MapConstants';
import './Legend.css';

const LegendItem = ({ layerId, layerOpacity, layerColor, onOpacityChange, darkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpacityChange = useCallback((_, newValue) => {
    onOpacityChange(layerId, newValue / 100);
  }, [layerId, onOpacityChange]);

  return (
    <Box className="legend-item">
      <Box
        onClick={() => setIsExpanded(!isExpanded)}
        className={`legend-item-header ${darkMode ? 'dark-mode' : ''}`}
      >
        <div 
          className="color-swatch" 
          style={{ backgroundColor: layerColor, opacity: layerOpacity }} 
        />
        <Typography variant="body2" className="legend-item-title">
          {layerId}
        </Typography>
        <IconButton size="small" className="expand-button">
          {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded}>
        <Box className="opacity-slider">
          <Slider
            value={layerOpacity * 100}
            onChange={handleOpacityChange}
            aria-labelledby={`layer-opacity-slider-${layerId}`}
            valueLabelDisplay="auto"
            min={0}
            max={100}
            step={1}
            size="small"
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const Legend = ({ layerOpacities, onLayerOpacityChange, darkMode }) => {
  const [isLegendExpanded, setIsLegendExpanded] = useState(true);
  const [layerColors] = useState(initialLayerColors);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sortedLayerEntries = initialLayers
    .filter(layerId => layerId in layerOpacities)
    .sort((a, b) => a.localeCompare(b))
    .map(layerId => [layerId, layerOpacities[layerId]]);

  return (
    <Card className={`legend-container ${darkMode ? 'dark-mode' : ''} ${!isLegendExpanded ? 'collapsed' : ''}`}>
      <Box className="legend-header" onClick={() => setIsLegendExpanded(!isLegendExpanded)}>
        <Typography variant="subtitle2" className="legend-title">
          <b>Legend</b>
        </Typography>
        <IconButton size="small" className="expand-button">
          {isLegendExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </IconButton>
      </Box>
      <Collapse in={isLegendExpanded}>
        <Box className="legend-content">
          <Box className="legend-items-list">
            {sortedLayerEntries.map(([layerId, opacity]) => (
              <LegendItem
                key={layerId}
                layerId={layerId}
                layerOpacity={opacity}
                layerColor={layerColors[layerId]}
                onOpacityChange={onLayerOpacityChange}
                darkMode={darkMode}
              />
            ))}
          </Box>
        </Box>
      </Collapse>
    </Card>
  );
};

LegendItem.propTypes = {
  layerId: PropTypes.string.isRequired,
  layerOpacity: PropTypes.number.isRequired,
  layerColor: PropTypes.string.isRequired,
  onOpacityChange: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

Legend.propTypes = {
  layerOpacities: PropTypes.objectOf(PropTypes.number).isRequired,
  onLayerOpacityChange: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default Legend;
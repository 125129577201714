import React from 'react';
import PageLayout from '../components/PageLayout';

const About = () => {
  const aboutLanternImage = 'https://pub-c192afd771d147c4a5015cd3c52b99cb.r2.dev/header.webp';
  
  return (
    <PageLayout>
      <div className="page-container">
        <img
          src={aboutLanternImage}
          alt="A campfire lights up the middle of a forest at dusk. A dome tent is set up to the left of the campfire, and two camp chairs are set up to the right of the campfire."
          style={{ width: '100%', display: 'block', margin: '0 auto 2rem' }}
        />
        
        <h2>About Lantern</h2>
        <p>Lantern is an interactive web app that displays Crown land in Ontario based on information from the Ontario government.</p>
        <p>
          Not all Crown land shown is open to the public for
          recreation under the <i><a href="https://www.ontario.ca/laws/statute/90p43" target="_blank" rel="noopener noreferrer">Public Lands Act</a></i>. Lantern may display Crown land that may be private or otherwise closed to the public.
        </p>
        <p>
          To determine which Crown land areas are open for public use and recreation, consult the <i><a href="https://www.ontario.ca/laws/statute/90p43" target="_blank" rel="noopener noreferrer">Public Lands Act</a></i> and local regulations.
        </p>
        <p>
          This website is not associated in any manner with the Government of Ontario. The information provided is for
          informational purposes only. The accuracy of the
          information cannot be guaranteed. Use at your own risk.
        </p>
        <h2>Support Lantern</h2>
        <p></p>
        <p>If you enjoy using Lantern, you're welcome to offer your support.</p>
        <p>Your support will enable Lantern's ongoing development, cover hosting costs, and help provide the best Lantern experience possible.</p>
        <a 
          className="btn btn-primary btn-lg"
          href="https://donate.stripe.com/eVacPZ8Bo1TOeS4144" 
          role="button"
          target="_blank" 
          rel="noopener noreferrer"
        >
          I want to support Lantern
        </a>
      </div>
    </PageLayout>
  );
};

export default About;
export const DEFAULT_LONGITUDE = -78.286;
export const DEFAULT_LATITUDE = 45.120;
export const DEFAULT_ZOOM = 8;
export const MAPBOX_STYLE = 'mapbox://styles/philgwmurphy/clv9fper900l101pe9cxz618j';
export const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-v9';

export const initialLayers = [
  "Conservation Reserve",
  "Enhanced Management Area",
  "Forest Reserve",
  "General Use Area",
  "Protected Area - Far North",
  "Provincial Park",
  "Provincial Wildlife Area",
  "Recommended Conservation Reserve",
  "Recommended Provincial Park",
  "Wilderness Area",
  "Ontario Trails"
];

export const initialLayerColors = {
  "Conservation Reserve": "#c2a5cf",
  "Enhanced Management Area": "#bc6c25",
  "Forest Reserve": "#7014f0",
  "General Use Area": "#a467da",
  "Protected Area - Far North": "#fa763d",
  "Provincial Park": "#64b5f6",
  "Provincial Wildlife Area": "#fed976",
  "Recommended Conservation Reserve": "#e78ac3",
  "Recommended Provincial Park": "#a1d99b",
  "Wilderness Area": "#f3e8d6",
  "Ontario Trails": "#FF0000"
};

export const PROVINCIAL_PARK_URLS = {
  "Aaron": "/park/aaron",
  "Algonquin": "/park/algonquin",
  "Arrowhead": "/park/arrowhead",
  "Arrow Lake": "/park/arrowlake",
  "Awenda": "/park/awenda",
  "Balsam Lake": "/park/balsamlake",
  "Bass Lake": "/park/basslake",
  "Batchawana Bay": "/park/batchawanabay",
  "Biscotasi Lake": "/park/biscotasilake",
  "Blue Lake": "/park/bluelake",
  "Bon Echo": "/park/bonecho",
  "Bonnechere": "/park/bonnechere",
  "Bronte Creek": "/park/brontecreek",
  "Caliper Lake": "/park/caliperlake",
  "Charleston Lake": "/park/charlestonlake",
  "Chutes": "/park/chutes",
  "Craigleith": "/park/craigleith",
  "Darlington": "/park/darlington",
  "Driftwood": "/park/driftwood",
  "Earl Rowe": "/park/earlrowe",
  "Emily": "/park/emily",
  "Esker Lakes": "/park/eskerlakes",
  "Fairbank": "/park/fairbank",
  "Ferris": "/park/ferris",
  "Finlayson Point": "/park/finlaysonpoint",
  "Fitzroy": "/park/fitzroy",
  "Forks of the Credit": "/park/forksofthecredit",
  "French River": "/park/frenchriver",
  "Frontenac": "/park/frontenac",
  "Fushimi Lake": "/park/fushimilake",
  "Grundy Lake": "/park/grundylake",
  "Halfway Lake": "/park/halfwaylake",
  "Inverhuron": "/park/inverhuron",
  "Ivanhoe Lake": "/park/ivanhoelake",
  "John E. Pearce": "/park/johnepearce",
  "Kakabeka Falls": "/park/kakabekafalls",
  "Kap-Kig-Iwan": "/park/kapkigiwan",
  "Kawartha Highlands": "/park/kawarthahighlands",
  "Kettle Lakes": "/park/kettlelakes",
  "Killarney": "/park/killarney",
  "Killbear": "/park/killbear",
  "Komoka": "/park/komoka",
  "Lady Evelyn-Smoothwater": "/park/ladyevelynsmoothwater",
  "Lake on the Mountain": "/park/lakeonthemountain",
  "Lake St. Peter": "/park/lakestpeter",
  "Lake Superior": "/park/lakesuperior",
  "Long Point": "/park/longpoint",
  "MacGregor Point": "/park/macgregorpoint",
  "MacLeod": "/park/macleod",
  "Makobe-Grays River": "/park/makobegraysriver",
  "Mara": "/park/mara",
  "Mark S. Burnham": "/park/marksburnham",
  "Marten River": "/park/martenriver",
  "McRae Point": "/park/mcraepoint",
  "Mikisew": "/park/mikisew",
  "Misery Bay": "/park/miserybay",
  "Missinaibi": "/park/missinaibi",
  "Mississagi": "/park/mississagi",
  "Mono Cliffs": "/park/monocliffs",
  "Murphys Point": "/park/murphyspoint",
  "Nagagamisis": "/park/nagagamisis",
  "Neys": "/park/neys",
  "North Beach": "/park/northbeach",
  "Oastler Lake": "/park/oastlerlake",
  "Obabika River": "/park/obabikariver",
  "Ojibway": "/park/ojibway",
  "Ouimet Canyon": "/park/ouimetcanyon",
  "Oxtongue River - Ragged Falls": "/park/oxtongueriverraggedfalls",
  "Pakwash": "/park/pakwash",
  "Pancake Bay": "/park/pancakebay",
  "Petroglyphs": "/park/petroglyphs",
  "Pigeon River": "/park/pigeonriver",
  "Pinery": "/park/pinery",
  "Point Farms": "/park/pointfarms",
  "Port Bruce": "/park/portbruce",
  "Port Burwell": "/park/portburwell",
  "Potholes": "/park/potholes",
  "Presqu'ile": "/park/presquile",
  "Quetico": "/park/quetico",
  "Queen Elizabeth II Wildlands": "park/queenelizabeth2wildlands",
  "Rainbow Falls": "/park/rainbowfalls",
  "René Brunelle": "/park/renebrunelle",
  "Restoule": "/park/restoule",
  "Rideau River": "/park/rideauriver",
  "Rock Point": "/park/rockpoint",
  "Rondeau": "/park/rondeau",
  "Rushing River": "/park/rushingriver",
  "Samuel de Champlain": "/park/samueldechamplain",
  "Sandbanks": "/park/sandbanks",
  "Sandbar Lake": "/park/sandbarlake",
  "Sauble Falls": "/park/saublefalls",
  "Selkirk": "/park/selkirk",
  "Sharbot Lake": "/park/sharbotlake",
  "Sibbald Point": "/park/sibbaldpoint",
  "Silent Lake": "/park/silentlake",
  "Silver Falls": "/park/silverfalls",
  "Silver Lake": "/park/silverlake",
  "Sioux Narrows": "/park/siouxnarrows",
  "Six Mile Lake": "/park/sixmilelake",
  "Sleeping Giant": "/park/sleepinggiant",
  "Solace": "/park/solace",
  "Spanish River": "/park/spanishriver",
  "Springwater": "/park/springwater",
  "Sturgeon Bay": "/park/sturgeonbay",
  "Sturgeon River": "/park/sturgeonriver",
  "The Massasauga": "/park/themassasauga",
  "Tidewater": "/park/tidewater",
  "Turkey Point": "/park/turkeypoint",
  "Voyageur": "/park/voyageur",
  "Wabakimi": "/park/wabakimi",
  "Wakami Lake": "/park/wakamilake",
  "Wasaga Beach": "/park/wasagabeach",
  "Wheatley": "/park/wheatley",
  "White Lake": "/park/whitelake",
  "Windy Lake": "/park/windylake",
  "Woodland Caribou": "/park/woodlandcaribou"
};
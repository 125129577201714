import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Map from './pages/Map';
import About from './pages/About';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import Updates from './pages/Updates';
import SuccessPage from './pages/SuccessPage';
import PageLayout from './components/PageLayout';
import { Analytics } from "@vercel/analytics/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const App = () => {
  return (
    <Router>
      <Analytics />
      <Navigation />
      <Routes>
        {/* Map doesn't use PageLayout because it needs full viewport */}
        <Route path="/" element={<Map />} />
        
        {/* Wrap other routes with PageLayout */}
        <Route
          path="/About"
          element={
            <PageLayout>
              <About />
            </PageLayout>
          }
        />
        <Route
          path="/FAQ"
          element={
            <PageLayout>
              <FAQ />
            </PageLayout>
          }
        />
        <Route
          path="/Contact"
          element={
            <PageLayout>
              <Contact />
            </PageLayout>
          }
        />
        <Route
          path="/Updates"
          element={
            <PageLayout>
              <Updates />
            </PageLayout>
          }
        />
        <Route
          path="/success/:sessionId"
          element={
            <PageLayout>
              <SuccessPage />
            </PageLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
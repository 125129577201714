import React from 'react';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  
  return (
    <div className="container mt-5">
    <div className="page-container">
      <h2>Send a message</h2>
      <ContactForm />
    </div>
    </div>
  );
};

export default Contact;

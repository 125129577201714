import React from 'react';
import { ListGroup } from 'react-bootstrap';

const Updates = () => {
  return (
    <div className="container mt-5">
      <div className="page-container">
        <h2>Updates to Lantern</h2>
        
        <h4 className="mt-4">October 19, 2024</h4>
        <ListGroup variant="flush">
          <ListGroup.Item>Improved legend UI for better user experience</ListGroup.Item>
          <ListGroup.Item>Overhauled info popup</ListGroup.Item>
          <ListGroup.Item>Added trail information popup for enhanced trail details</ListGroup.Item>
          <ListGroup.Item>Included area names for better location identification</ListGroup.Item>
          <ListGroup.Item>Added permitted activities for each area to provide more comprehensive information</ListGroup.Item>
          <ListGroup.Item>Welcome popup now only shows when Lantern is first loaded</ListGroup.Item>
        </ListGroup>

        <h4 className="mt-4">In Development</h4>
        <ListGroup variant="flush">
          <ListGroup.Item>Offline availability - We're working on making Lantern accessible without an internet connection</ListGroup.Item>
        </ListGroup>
     


        <h4 className="mt-4">April 24, 2024</h4>
        <ListGroup variant="flush">
          <ListGroup.Item>Crown land layers from Crown land use policy atlas added</ListGroup.Item>
          <ListGroup.Item>Legend on mobile now a more appropriate size. Tap/click the legend to expand or collapse it</ListGroup.Item>
          <ListGroup.Item>Layer opacity is now reflected in the layer color indicator</ListGroup.Item>
          <ListGroup.Item>Disclaimer popup added to inform users about the source of Lantern's data</ListGroup.Item>
        </ListGroup>

        <h4 className="mt-4">April 17, 2024</h4>
        <ListGroup variant="flush">
          <ListGroup.Item>Dark mode added. Toggle dark mode in the menu</ListGroup.Item>
          <ListGroup.Item><s>Lantern now displays one single layer for Crown land</s></ListGroup.Item>
          <ListGroup.Item>Crown land layer now includes provincial parks</ListGroup.Item>
          <ListGroup.Item>Map scale added to bottom right corner</ListGroup.Item>
          <ListGroup.Item>Map popup simplified</ListGroup.Item>
          <ListGroup.Item>Simplified the legend. Tap/click the legend to expand or collapse it</ListGroup.Item>
        </ListGroup>
        </div>
        </div>
  );
};   

export default Updates;
import React from 'react';
import { Accordion, AccordionItem } from 'react-bootstrap';

const styles = `
  .accordion {
    width: 100%;
  }
  .accordion-item {
    border: 1px solid rgba(0,0,0,.125);
    margin-bottom: 10px;
  }
  .accordion-button {
    width: 100%;
    text-align: left;
  }
  .accordion-body {
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 15px;
  }
  @media (max-width: 768px) {
    .faq-container {
      max-width: 100vh;
    }
  }
`;

const faqs = [
  {
    id: "collapseOne",
    question: "What kind of land is displayed on Lantern?",
    answer: (
      <span>
        Lantern displays Crown land in Ontario based on information from the Government of Ontario. However, not all Crown land shown is open to the public for recreation under the <i><a href="https://www.ontario.ca/laws/statute/90p43" target="_blank" rel="noopener noreferrer">Public Lands Act</a></i>.
      </span>
    )
  },
  {
    id: "collapseTwo",
    question: "Can I camp on any Crown land shown on Lantern?",
    answer: (
      <span>
        No, not all Crown land shown on Lantern is open for camping or public recreation. The <i><a href="https://www.ontario.ca/laws/statute/90p43" target="_blank" rel="noopener noreferrer">Public Lands Act</a></i> allows the public to use some Crown land for recreation, subject to conditions. However, certain lands may not be open to the public without authorization from the leaseholder.
      </span>
    )
  },
  {
    id: "collapseThree",
    question: "I really enjoy using Lantern. How can I support it?",
    answer: (
      <span>
        It's great to hear you're enjoying the tool. Your support will help maintain and improve Lantern. <a href="https://donate.stripe.com/eVacPZ8Bo1TOeS4144" target="_blank" rel="noopener noreferrer">Support now</a>.
      </span>
    )
  }
];

const FAQ = () => {
  return (
    <div className="container mt-5">
      <style>{styles}</style>
      <div className="faq-container">
        <h2 className="mb-4">Frequently asked questions</h2>
        <Accordion>
          {faqs.map((faq) => (
            <AccordionItem eventKey={faq.id} key={faq.id}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>{faq.answer}</Accordion.Body>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
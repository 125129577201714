import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("mpzvyeor");

  if (state.succeeded) {
      return <p>Thanks for your message!</p>;
  }

  return (
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="full-name">Name</label>
        <input
          type="text"
          name="name"
          id="full-name"
          placeholder="Your name"
          required
        />
        <ValidationError
          prefix="Name"
          field="name"
          errors={state.errors}
        />
        
        <label htmlFor="email-address">Email address</label>
        <input
          type="email"
          name="_replyto"
          id="email-address"
          placeholder="example@gmail.com"
          required
        />
        <ValidationError
          prefix="Email"
          field="_replyto"
          errors={state.errors}
        />
        
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          placeholder="Enter your message here..."
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        
        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
  );
}

export default ContactForm;
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Modal, Button } from 'react-bootstrap';
import Legend from '../components/Legend';
import CustomModal from '../components/CustomModal';
// import FilterActivities from '../components/FilterActivities';
import { DEFAULT_LONGITUDE, DEFAULT_LATITUDE, DEFAULT_ZOOM, MAPBOX_STYLE, initialLayers, initialLayerColors } from '../components/MapConstants';
import './Map.css';

const Map = () => {
  // Refs
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  // State declarations
  const [map, setMap] = useState(null);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [layerOpacities, setLayerOpacities] = useState(
    Object.fromEntries(initialLayers.map(layerName => [layerName, 0.65]))
  );
  const [layerColors, setLayerColors] = useState(initialLayerColors);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    feature: null,
    lngLat: null,
    color: '',
    areaName: '',
  });
  const [darkMode, setDarkMode] = useState(false);

  // Callback functions
  const updateLayerColor = useCallback((layerName, color) => {
    setLayerColors(prev => ({ ...prev, [layerName]: color }));
  }, []);

  const updateLayerOpacity = useCallback((layerName, opacity) => {
    setLayerOpacities(prev => ({ ...prev, [layerName]: opacity }));
  }, []);

  const handleMapClick = useCallback((e) => {
    if (!map) return;

    const clickTolerance = 5;
    const bbox = [
      [e.point.x - clickTolerance, e.point.y - clickTolerance],
      [e.point.x + clickTolerance, e.point.y + clickTolerance]
    ];

    const trailFeatures = map.getLayer('Ontario trails') ? map.queryRenderedFeatures(bbox, { layers: ['Ontario trails'] }) : [];
    const otherFeatures = trailFeatures.length === 0 
      ? map.queryRenderedFeatures(bbox, { layers: initialLayers.filter(layer => map.getLayer(layer)) })
      : [];

    const features = [...trailFeatures, ...otherFeatures];

    if (features.length) {
      const feature = features[0];
      
      const newModalInfo = {
        feature: feature,
        lngLat: e.lngLat,
        color: (feature && layerColors[feature.layer.id]) || '#FFF',
        areaName: (feature && (feature.properties['AREA_NAME'] || feature.properties['TRAIL_NAME'])) || 'Unknown Area'
      };
      setModalInfo(newModalInfo);
      setIsModalOpen(true);

      map.flyTo({
        center: e.lngLat,
        zoom: map.getZoom(),
        speed: 0.5,
        curve: 1
      });
      
      if (markerRef.current) {
        markerRef.current.remove();
      }

      const markerElement = document.createElement('div');
      markerElement.className = 'click-marker';
      markerRef.current = new mapboxgl.Marker(markerElement)
        .setLngLat(e.lngLat)
        .addTo(map);
    } else {
      setIsModalOpen(false);
    }
  }, [map, layerColors]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  // Effects
  useEffect(() => {
    const hasSeenWelcome = sessionStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setShowWelcomeModal(true);
      sessionStorage.setItem('hasSeenWelcome', 'true');
    }
  }, []);

  useEffect(() => {
    if (map) return;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: MAPBOX_STYLE,
      center: [DEFAULT_LONGITUDE, DEFAULT_LATITUDE],
      zoom: DEFAULT_ZOOM,
    });

    newMap.on('load', () => {
      setMap(newMap);
      mapRef.current = newMap;
      
      initialLayers.forEach(layerName => {
        if (newMap.getLayer(layerName)) {
          if (layerName === 'Ontario trails') {
            newMap.setPaintProperty(layerName, 'line-opacity', layerOpacities[layerName]);
            newMap.setPaintProperty(layerName, 'line-color', layerColors[layerName]);
          } else {
            newMap.setPaintProperty(layerName, 'fill-opacity', layerOpacities[layerName]);
            newMap.setPaintProperty(layerName, 'fill-color', layerColors[layerName]);
          }
        }
      });

      if (newMap.getLayer('Ontario trails')) {
        newMap.moveLayer('Ontario trails');
      }

      newMap.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl, marker: false, zoom: 13 }), 'top-right');
      newMap.addControl(new mapboxgl.NavigationControl(), 'top-right');
      newMap.addControl(new mapboxgl.ScaleControl({ maxWidth: 100, unit: 'metric' }), 'bottom-right');

      newMap.on('click', handleMapClick);
    });

    return () => {
      if (newMap) {
        newMap.off('click', handleMapClick);
        newMap.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (map) {
      map.on('click', handleMapClick);
      return () => map.off('click', handleMapClick);
    }
  }, [map, handleMapClick]);

  useEffect(() => {
    if (map) {
      Object.entries(layerOpacities).forEach(([layerName, opacity]) => {
        if (map.getLayer(layerName)) {
          if (layerName === 'Ontario Trails') {
            map.setPaintProperty(layerName, 'line-opacity', opacity);
            map.setPaintProperty(layerName, 'line-color', layerColors[layerName]);
          } else {
            map.setPaintProperty(layerName, 'fill-opacity', opacity);
            map.setPaintProperty(layerName, 'fill-color', layerColors[layerName]);
          }
        }
      });
    }
  }, [map, layerOpacities, layerColors]);

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          setDarkMode(document.body.classList.contains('dark-mode'));
        }
      });
    });

    observer.observe(document.body, { attributes: true });

    return () => observer.disconnect();
  }, []);

  const legendProps = useMemo(() => ({
    layerOpacities,
    onLayerOpacityChange: updateLayerOpacity,
    layerColors,
    onLayerColorChange: updateLayerColor,
    darkMode
  }), [layerOpacities, updateLayerOpacity, layerColors, updateLayerColor, darkMode]);

  return (
    <div className="map-layout">
      <div className="map-container">
        <div ref={mapContainerRef} className="map-wrapper" />
        
        {/* Commented out FilterActivities for now
        <FilterActivities 
          onFilterChange={handleFilterChange}
          darkMode={darkMode}
          isLoading={isLoadingActivities}
        />
        */}

        <Modal show={showWelcomeModal} onHide={() => setShowWelcomeModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Welcome to Lantern</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please note that Lantern displays the same map layers that are displayed on the Crown Land Use Policy Atlas. 
            Any resulting inaccuracies are due to the information on the Crown Land Use Policy Atlas.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowWelcomeModal(false)}>Got it!</Button>
          </Modal.Footer>
        </Modal>

        <Legend {...legendProps} />

        <CustomModal
          feature={modalInfo.feature}
          lngLat={modalInfo.lngLat}
          color={modalInfo.color}
          onClose={handleCloseModal}
          isOpen={isModalOpen}
          darkMode={darkMode}
        />
      </div>
    </div>
  );
};

export default React.memo(Map);
import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { X, MapPin, Share2, ExternalLink, Loader, ChevronDown, ChevronUp } from 'lucide-react';
import { formatGoogleMapsLink } from './locationUtils';
import './CustomModal.css';
import { PROVINCIAL_PARK_URLS } from './MapConstants';

const EXCEPTIONS = {
  "Arrow": "Arrow Lake",
  "Lake": "Lake Superior",
  "MacGregor": "MacGregor Point",
  "Silent": "Silent Lake",
  "Silver": "Silver Falls",  // Default to "Silver Falls"
  "Silver Lake": "Silver Lake", // Use full name for Silver Lake
  "Mark": "Mark S. Burnham",
  "Lady Evelyn": "Lady Evelyn-Smoothwater",
  "Oxtongue": "Oxtongue River - Ragged Falls",
  "The": "The Massasauga",
  // Add other exceptions as needed
};

const CustomModal = ({ isOpen, onClose, feature, lngLat, color, darkMode }) => {
  const [permittedActivities, setPermittedActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedActivities, setExpandedActivities] = useState({});

  const modalRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const deduplicateActivities = useCallback((activities) => {
    const dedupedActivities = [];
    activities.forEach(activity => {
      const existingActivity = dedupedActivities.find(
        a => a.activity === activity.activity && a.permitted === activity.permitted
      );
      if (existingActivity) {
        if (activity.guidelines) {
          existingActivity.guidelines = [...new Set([...existingActivity.guidelines, activity.guidelines])];
        }
      } else {
        dedupedActivities.push({
          ...activity,
          guidelines: activity.guidelines ? [activity.guidelines] : []
        });
      }
    });
    return dedupedActivities;
  }, []);

  const getFirstWord = (name) => {
    const words = name.split(' ');
    const firstTwoWords = `${words[0]} ${words[1]}`;
  
    // Check if the first two words match any exceptions
    if (EXCEPTIONS[firstTwoWords]) {
      return firstTwoWords;
    }
  
    // Check if the first word alone matches any exceptions
    return EXCEPTIONS[words[0]] || words[0];
  };
  

  const handleViewProvincialParkPage = () => {
    const parkName = getDisplayName().name;
    const firstWord = getFirstWord(parkName);
  
    // Use the exception or the first word to find the URL
    const matchingName = EXCEPTIONS[firstWord] || firstWord;
  
    // Get the correct URL from PROVINCIAL_PARK_URLS
    const parkUrl = PROVINCIAL_PARK_URLS[matchingName];
    
    if (parkUrl) {
      const fullUrl = `https://www.ontarioparks.ca${parkUrl}`;
      window.open(fullUrl, '_blank', 'noopener,noreferrer');
    } else {
      console.error(`No URL found for park: ${matchingName}`);
    }
  };
  
  useEffect(() => {
    const fetchActivities = async () => {
        if (!feature?.properties) return;

        setLoading(true);
        setError(null);

        try {
            let activities = [];

            // Check if the feature is a Provincial Park
            if (feature.properties.DESIG_ENG === "Provincial Park" && feature.properties.NAME_ENG) {
                const parkName = feature.properties.NAME_ENG.toLowerCase().replace(/\s+/g, '');
                activities = await fetchProvincialParkActivities(parkName);
            } else if (feature.properties.POL_IDENT && !feature.properties.TRAIL_NAME) {
                // Check for other designations
                const allowedDesignations = [
                    "Conservation Reserve",
                    "Recommended Conservation Reserve",
                    "General Use Area",
                    "Enhanced Management Area",
                    "Provincial Wildlife Area",
                    "Forest Reserve"
                ];

                if (allowedDesignations.includes(feature.properties.DESIG_ENG)) {
                    const response = await fetch(`/api/policy-ident?policyIdent=${feature.properties.POL_IDENT}`);
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    activities = await response.json();
                }
            }

            const dedupedActivities = deduplicateActivities(activities);
            setPermittedActivities(dedupedActivities);
        } catch (error) {
            console.error('Failed to fetch activities:', error);
            setError(`Failed to load activities: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    if (isOpen && feature) {
        fetchActivities();
    }
}, [isOpen, feature, deduplicateActivities]);

  if (!isOpen || !feature) {
    return null;
  }
  
  const isProvincialPark = feature.properties.DESIG_ENG === "Provincial Park";

  const isTrail = feature.properties.TRAIL_NAME !== undefined;

  const getPermittedTransportation = () => {
    if (!feature.properties) {
      return [];
    }
    
    const permittedString = feature.properties.PERMITTED_;
    if (!permittedString) {
      return [];
    }
    
    return permittedString.split(', ').map(type => type.trim());
  };

  const permittedTransportation = getPermittedTransportation();

  const coordinates = lngLat ? `${lngLat.lat.toFixed(6)}, ${lngLat.lng.toFixed(6)}` : 'Coordinates unavailable';
  const googleMapsDirectionsLink = lngLat ? formatGoogleMapsLink(lngLat) : '#';
  const policyReportLink = feature?.properties?.POL_IDENT
    ? `https://www.lioapplications.lrc.gov.on.ca/services/CLUPA/xmlReader.aspx?xsl=web-primary.xsl&type=primary&POLICY_IDENT=${feature.properties.POL_IDENT}`
    : '#';

  const handleShare = () => {
    if (googleMapsDirectionsLink !== '#') {
      navigator.clipboard.writeText(googleMapsDirectionsLink)
        .then(() => alert('Link copied to clipboard!'))
        .catch(err => console.error('Failed to copy link: ', err));
    } else {
      alert('No coordinates available to share.');
    }
  };

  const handleDirections = (e) => {
    e.preventDefault();
    if (googleMapsDirectionsLink !== '#') {
      window.open(googleMapsDirectionsLink, '_blank', 'noopener,noreferrer');
    } else {
      alert('No coordinates available for directions.');
    }
  };

  const handleViewPolicyReport = (e) => {
    e.preventDefault();
    if (policyReportLink !== '#') {
      window.open(policyReportLink, '_blank', 'noopener,noreferrer');
    } else {
      alert('No policy report available for this area.');
    }
  };

  const toggleActivityExpansion = (index) => {
    setExpandedActivities(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderActivityItem = (activity, index) => {
    const hasGuidelines = activity.guidelines.length > 0;
    return (
      <li key={index} className={`activity-item ${darkMode ? 'dark-mode' : ''}`}>
        <div className="activity-header">
          <span className={`activity-name ${darkMode ? 'dark-mode' : ''}`}>{activity.activity}</span>
          <div className="activity-status">
            <span className={`status-indicator ${activity.permitted.toLowerCase()} ${darkMode ? 'dark-mode' : ''}`}>
              {activity.permitted}
            </span>
          </div>
        </div>
        {hasGuidelines && (
          <div className="guidelines-container">
            <button 
              className={`expand-button ${darkMode ? 'dark-mode' : ''}`}
              onClick={() => toggleActivityExpansion(index)}
              aria-label={expandedActivities[index] ? "Collapse guidelines" : "Expand guidelines"}
            >
              {expandedActivities[index] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              <span className="read-more-text">
                {expandedActivities[index] ? "Read less" : "Read more"}
              </span>
            </button>
            {expandedActivities[index] && (
              <p className={`guidelines-paragraph ${darkMode ? 'dark-mode' : ''}`}>
                {activity.guidelines.join(' ')}
              </p>
            )}
          </div>
        )}
      </li>
    );
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const getDisplayName = () => {
    if (feature.properties.DESIG_ENG === "Provincial Park" && feature.properties.NAME_ENG) {
      const classificationsToRemove = [
        "NATURAL ENVIRONMENT CLASS",
        "NATURE RESERVE CLASS",
        "WATERWAY CLASS",
        "WILDERNESS CLASS",
        "CULTURAL HERITAGE CLASS",
        "RECREATIONAL CLASS"
      ];
      let name = feature.properties.NAME_ENG;
      classificationsToRemove.forEach(classification => {
        name = name.replace(` (${classification})`, "");
      });
      return {
        name: toTitleCase(name),
        type: "Provincial Park"
      };
    } else if (feature.properties.TRAIL_NAME) {
      return {
        name: feature.properties.TRAIL_NAME,
        type: "Trail"
      };
    } else {
      return {
        name: feature.properties.NAME_ENG ? toTitleCase(feature.properties.NAME_ENG) : "Unnamed Feature",
        type: feature.properties['DESIG_ENG'] || 'Unknown Designation'
      };
    }
  };

  return (
    <div className={`custom-modal-overlay ${darkMode ? 'dark-mode' : ''}`}>
      <div ref={modalRef} className={`custom-modal ${darkMode ? 'dark-mode' : ''}`}>
        <div className="modal-header">
          <div className={`modal-title ${darkMode ? 'dark-mode' : ''} ${isTrail ? 'trail-title' : ''}`}>
            <h2 className="feature-name">{getDisplayName().name}</h2>
            <h3 className="feature-type">{getDisplayName().type}</h3>
          </div>
          <button onClick={onClose} className={`close-button ${darkMode ? 'dark-mode' : ''}`}>
            <X size={20} />
          </button>
        </div>
        <div className={`modal-content ${darkMode ? 'dark-mode' : ''}`}>
          {permittedTransportation.length > 0 && (
            <div className={`permitted-transportation ${darkMode ? 'dark-mode' : ''}`}>
              <div className={`coordinates ${darkMode ? 'dark-mode' : ''}`}>
                <MapPin size={16} />
                <span>{coordinates}</span>
              </div>
              <h4>Permitted transportation</h4>
              <ul>
                {permittedTransportation.map((type, index) => (
                  <li key={index}>{type}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="action-buttons">
            <button onClick={handleDirections} className={`button button-primary ${darkMode ? 'dark-mode' : ''}`} disabled={!lngLat}>
              <MapPin size={16} />
              Directions
            </button>
            <button onClick={handleShare} className={`button button-secondary ${darkMode ? 'dark-mode' : ''}`} disabled={!lngLat}>
              <Share2 size={16} />
              Share
            </button>
            {!isTrail && (
              <button 
                onClick={handleViewPolicyReport}
                className={`button button-primary ${darkMode ? 'dark-mode' : ''}`}
                disabled={!feature?.properties?.POL_IDENT}
              >
                <ExternalLink size={16} />
                View policy report
              </button>
            )}
            {isProvincialPark && PROVINCIAL_PARK_URLS[getFirstWord(getDisplayName().name)] && (
            <button 
              onClick={handleViewProvincialParkPage}
              className={`button button-primary ${darkMode ? 'dark-mode' : ''}`}
            >
              <ExternalLink size={16} />
              Visit park page
            </button>
          )}
        </div>

          {!isTrail && feature.properties.DESIG_ENG !== "Provincial Park" && (
            <div className={`permitted-activities-wrapper ${darkMode ? 'dark-mode' : ''}`}>
              <h4 className={darkMode ? 'dark-mode' : ''}>Permitted activities</h4>
              <div className={`permitted-activities-content ${darkMode ? 'dark-mode' : ''}`}>
                {loading ? (
                  <div className="loading">
                    <Loader size={24} className={`spinner ${darkMode ? 'dark-mode' : ''}`} />
                  </div>
                ) : error ? (
                  <p className={`error ${darkMode ? 'dark-mode' : ''}`}>{error}</p>
                ) : permittedActivities.length > 0 ? (
                  <ul className={`activities-list ${darkMode ? 'dark-mode' : ''}`}>
                    {permittedActivities.map((activity, index) => renderActivityItem(activity, index))}
                  </ul>
                ) : (
                  <p className={`no-activities ${darkMode ? 'dark-mode' : ''}`}>No permitted activities found.</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  feature: PropTypes.object,
  lngLat: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  color: PropTypes.string,
  darkMode: PropTypes.bool.isRequired,
};

export default CustomModal;
import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';  // Importing Bootstrap components

function SuccessPage() {
  const { sessionId } = useParams();

  const handleDownload = () => {
    // This would ideally verify the session first and then redirect
    const fileUrl = 'https://pub-c192afd771d147c4a5015cd3c52b99cb.r2.dev/Lantern_Crown_land_layers.zip';
    window.location.href = fileUrl;
  };

  return (
    <Container className="mt-5">  {/* Using React-Bootstrap Container for consistent spacing */}
      <div className="page-container">  {/* Custom container class for additional styling if needed */}
        <h1>Thank you for your support!</h1>
        <p className="lead">Your transaction has been completed. You can download the compressed MBTile files by clicking the button below.</p>
        <Button variant="primary" onClick={handleDownload}>Download File</Button> {/* Using React-Bootstrap Button for styling */}
      </div>
    </Container>
  );
}

export default SuccessPage;
